import React from 'react'
import Helmet from 'react-helmet'

import Navbar from '../components/Navbar'
import 'bootstrap/scss/bootstrap.scss'
import './style/all.scss';
import Footer from "./Footer";

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet title="Conity | Espace Conseil"/>
    <Navbar />
    {children}
    <Footer/>
  </div>
)

export default TemplateWrapper
