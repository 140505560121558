import React from 'react'

const Footer = () => (
    <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 col-md-10 mx-auto text-center">
                    <p className="copyright text-muted">Copyright &copy; CONITY</p>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
